// 
// authentication
// 

// 

.authentication-bg {
    background-color: rgba($primary, 0.03);
    .auth-box {
        padding: 0px 60px;
        border-radius: 20px;
        .auth-form {
            label {
                font-size: 15px;
            }
            a {
                font-size: 14px;
                color: rgba(3, 2, 54, 1);
            }
            .form-control {
                padding: 12px;
                padding-left: 20px;
                border-radius: 30px;
            }
        }
        ::placeholder {
            color: rgba($muted, 0.7);
            font-size: 15px;
        }
        .form-link {
            color: rgba(3, 2, 54, 1) !important;
        }
    }
}
