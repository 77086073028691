.modal__bg {
    /* margin-top: 25px; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(28, 28, 28, 0.19);
    /* background-color: red; */
    backdrop-filter: blur(6px);
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    z-index: 100000 !important;
  }
  
  .modal__align {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  
  }
  
  .modal__content {
    width: 800px;
    height: 500px;
    box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07),
      0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
      0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
      0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
    border-radius: 20px;
    background: transparent;
    color: #000;
    margin: 0rem 4rem;
  }
  
  .modal__close {
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    bottom: 50px;
    width: 32px;
    height: 32px;
    padding: 0;
  }
  
  .modal__video-align {
    display: flex;
    position: relative;
    bottom: 37px;
  }
  
  .modal__video-style {
    display: flex;
    justify-items: center;
    /* margin-top: 120px; */
    /* border-radius: 20px; */
    /* height: 500px; */
    width: 100%;
    /* z-index: 100; */
  }
  .modal__video-style-div {
    margin-top: 90px;
    display: flex;
    justify-items: center;
    /* background-color: red; */
    /* border-radius: 20px; */
    height: 100%;
    width: 100%;
    z-index: 100;
  }
  .overlayText {
    position: absolute;
    top: 45%;
    left: 58%;
    z-index: 1;
    display: flex;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 15px;
    /* flex-direction: row; */
    background-color: rgba(0, 0, 0, 0.7);
    /* justify-content: ; */
  }
  
  .accountTextSecond {
    cursor: pointer;
    position: absolute;
    top: 85%;
    left: 36%;
    z-index: 1;
    display: flex;
    height: 6vw !important;
    /* padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px; */
    background-color: #B8202C !important;
    border-radius: 70px !important;
  }
  .accountTextSecond:hover{
    background-color: #ad000b !important;
  }

  
  .accountText {
    cursor: pointer;
    position: absolute;
    top: 75%;
    /* left: 57%; */
    z-index: 1;
    display: flex;
    height: 7vw;
    /* padding-left: 20px; */
    /* padding: 10px 30px 5px 30px; */
    /* padding-top: 20px; */
    /* padding-left: 20px; */
    /* padding-right: 20px; */
    background-color: #B8202C !important;
    border-radius: 50px;
    text-transform: capitalize;
  }

  @media screen and (max-width: 520px) {
    
    .accountText {
      cursor: pointer;
      position: absolute;
      top: 65vw !important;
      left: 52vw !important;
      z-index: 1;
      display: flex;
      height: 7vw;
      /* padding-left: 20px; */
      /* padding: 10px 30px 5px 30px; */
      /* padding-top: 20px; */
      /* padding-left: 20px; */
      /* padding-right: 20px; */
      background-color: #B8202C !important;
      border-radius: 50px;
      text-transform: capitalize;
    }
    .accountTextSecond {
      cursor: pointer;
      position: absolute;
      top: 28%;
      left: 36%;
      z-index: 1;
      display: flex;
      height: 12vw;
      /* padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px; */
      background-color: #B8202C !important;
      border-radius: 50px;
    }
    .accountTextSecond:hover{
      background-color: #ad000b !important;
    }
    .video-topText {
      display: flex;
      color: rgb(255, 255, 255);
      font-size: 3vw;
      align-self: center;
     
      white-space: nowrap;
   
  
    }
    .overlayText {
      position: absolute;
      top: 20%;
      left: 51%;
      z-index: 1;
      display: flex;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 15px;
      /* flex-direction: row; */
      background-color: rgba(0, 0, 0, 0.7);
      /* justify-content: ; */
    }
    .modal__video-style-div {
      margin-top: 120px;
      display: flex;
      justify-items: center;
      /* background-color: red; */
      /* border-radius: 20px; */
      height: 100%;
      width: 100%;
      z-index: 100;
    }

  }
  @media (max-width:1100px) and (min-width: 520px) {
    
    .accountText {
      cursor: pointer;
      position: absolute;
      top: 45vw !important;
      left:54vw !important;
      z-index: 1;
      display: flex;
      height: 7vw;
      /* padding-left: 20px; */
      /* padding: 10px 30px 5px 30px; */
      /* padding-top: 20px; */
      /* padding-left: 20px; */
      /* padding-right: 20px; */
      background-color: #B8202C !important;
      border-radius: 50px !important;
      text-transform: capitalize;
    }

    .accountTextSecond {
      cursor: pointer;
      position: absolute;
      top: 20% !important;
      left: 36%;
      z-index: 1;
      display: flex;
      height: 10vw;
      /* padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px; */
      background-color: #B8202C !important;
      border-radius: 50px !important;
    }
    .accountTextSecond:hover{
      background-color: #ad000b !important;
    }
   .overlayText {
      position: absolute;
      top: 20%;
      left: 53%;
      z-index: 1;
      display: flex;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 15px;
      /* flex-direction: row; */
      background-color: rgba(0, 0, 0, 0.7);
      /* justify-content: ; */
    }
  }

  .accountText:hover{
    background-color: #ad000b !important;
  }

  .clickHere{
    cursor: pointer;
    border-bottom: 1px solid #252D31 !important;
    /* border-bottom: 4px !important; */
    /* border-color: red; */
  }
  .clickHere:hover{
    color: #D63944 !important;
  }

  
  .video-topText {
    display: flex;
    color: rgb(255, 255, 255);
    font-size: 3vw;
    align-self: center;
    margin-top: 0px;

  }
  .video-topText2 {
    display: flex;
    color: rgb(255, 255, 255);
    /* font-size: 3vw; */
    align-self: center;
    margin-top: 0px;

  }
 
  .play-video-btn-svg{
    position: absolute;
    /* width: 1vw;
    height: 3vw; */
  
      }
.authNav{
  background-color: #ad000b;
  display: flex;
  flex-direction: column;
}

.table_space{
  white-space: nowrap;
}
     
     #thumbnailText {
      position: absolute;
      /* top: 70%; */
      left: 50%;
      color: aqua;
      /* transform: translateX(-50%); */
      z-index: 1;
   }
  
  .modal__spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .modal__spinner {
    animation: spin 2s linear infinite;
    font-size: 40px;
    /* color: #1b6aae; */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media screen and (max-width: 800px) {
    .modal__content {
      margin: 0rem 1rem;
      width: 100%;
    }
    .modal__video-style {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 499px) {
    .modal__content {
      background: transparent;
      height: auto;
    }
    .modal__video-align {
      bottom: 0px;
    }
    .modal__video-style {
      height: auto;
    }
  }
 

input[type='radio'],input[type='checkbox'] {
  accent-color: #B8202C;
}

textarea:focus { 
outline: none !important;

/* box-shadow: 0 0 10px #719ECE; */
}
input:focus { 
outline: none !important;

}
input[type='checkbox']:focus { 
outline: none !important;

}

select:focus {
outline: none !important;
}

