
.mainLayout{
    display: flex;
    justify-content: center;
    /* background-color: red; */
    width: 1300px;
    flex-direction: column;

}

.contentCard{
    padding: 20px;
    display: block;
    margin-bottom: 6px;
    
    /* background-color: aqua; */
    border: none;
    border-radius: 10px;
    box-shadow: 0px 8px 20px -6px rgba(183, 183, 183, 0.462);
}

.adminUserInfo{
    display:flex;
    flex-direction: column;
 
    /* max-width:900px; */
    padding: 20px;
   
    padding-bottom:28px ;
    width:100%;
    margin:0 auto;
    box-shadow:5px 3px 8px rgba(0,0,0,0.1);
    margin-top: 150px;
    background-color: rgb(251, 251, 251);


}
.table_User{
  overflow-x: auto;
}
.adminUserInfo1{
  display:flex;
  flex-direction: column;

  /* max-width:900px; */
  /* padding: 8px; */
 
  padding-bottom:28px ;
  width:100%;
  margin:0 auto;
  /* box-shadow:5px 3px 8px rgba(0,0,0,0.1); */
  margin-top: 135px;
  /* background-color: rgb(251, 251, 251); */


}

.main-table{
    /* width: 800px; */
}

.mainLayoutUser{
    display: flex;
    justify-content: center;
    /* background-color: red; */
    /* width: 500px; */
    padding-right: 5px;
    padding-left: 5px;
    flex-direction: column;

}
.mainDetail{
  display: flex;
}

.mainLayoutUser1{
    display: flex;
    flex-direction: row;
   
    justify-content: space-evenly;
    /* background-color: red; */
    /* width: 500px; */
    padding-right: 20px;
    padding-left: 20px;
    /* flex-direction: column; */

}
.UserformLabel{
font-size: 14px;
margin-bottom: -2px;
}
.UserformInput{
  border-radius: 12px;
  font-size: 14px;
padding: 9px;
  background-color: #f8f8f8;
}


.mainUserInfo{
  display: flex;
  flex-direction: column;
  width: 40%;
  /* background-color: antiquewhite; */
  padding-right: 30px;
  padding-left: 30px;
}
.mainCardInfo{
  display: flex;
  width: 40%;
  flex-direction: column;
  /* background-color: rgb(131, 112, 87); */
  padding-right: 30px;
  padding-left: 30px;
}

.mainLayoutUser2{
  display: flex;
  justify-content: center;
  /* background-color: red; */
  /* width: 900px; */
  /* padding-right: 260px;
  padding-left: 260px; */
  flex-direction: column;

}

.contentCardUser{
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
    /* width: 500px; */
    background-color: rgba(251, 241, 240, 0.644);
    border: none;
    border-radius: 10px;
    box-shadow: 0px 8px 20px -6px rgba(183, 183, 183, 0.462);
}

.contentSvg{
    display: flex;
    justify-content: space-between;
}

.contentDetail{
    display: flex;
    flex-direction: column;
}
.delsvg{
    /* width: 10px; */
    color: red;
    cursor: pointer;
}
.editsvg{
    
    color: rgb(144, 142, 142);
    cursor: pointer;
}
.svgCon{
    display: flex;
    gap: 8px;
    /* justify-content: space-between; */
    /* padding-left: 5px; */
    /* background-color: aqua; */
}

.delete_btn_content{
  background-color: rgb(203, 201, 201) !important;
  border: none;
}
.delete_btn_content:hover, .delete_btn_content:focus, .delete_btn_content:active, .delete_btn_content.active, .delete_btn_content.focus, .delete_btn_content:not(:disabled):not(.disabled):active, .delete_btn_content:not(:disabled):not(.disabled):active:focus,
.delete_btn_content .open > .dropdown-toggle.delete_btn_content {
 color: black;

  box-shadow: 0px 8px 20px -6px rgba(207, 206, 206, 0.6);
  transform: translateY(-6px);
}

.svgDiv{
    display: flex;
    flex-direction: row;
  
    /* background-color: aqua; */
    /* padding: 5px; */
    align-items: center;

}

.admin_content_lay{
  display: flex;
  justify-content: space-between;
  /* background-color: #B8202C; */
}
.admin_svg_lay{
display: flex;
/* background-color: aliceblue; */
gap:15px

}

.update_btn_content{
  background-color: rgb(77, 200, 68) !important;
  color: #f8f8f8;
  border: none;
}
.update_btn_content:hover, .update_btn_content:focus, .update_btn_content:active, .update_btn_content.active, .update_btn_content.focus, .update_btn_content:not(:disabled):not(.disabled):active, .update_btn_content:not(:disabled):not(.disabled):active:focus,
.update_btn_content .open > .dropdown-toggle.update_btn_content {
 color:  #f8f8f8;

  box-shadow: 0px 8px 20px -6px rgba(188, 188, 188, 0.6);
  transform: translateY(-6px);
}


.modal1__bg {
    /* margin-top: 25px; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(214, 214, 214, 0.19);
    /* background-color: red; */
    backdrop-filter: blur(3px);
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    z-index: 100000 !important;
  }
  
.modal1__btn{
    width: 120px;
    height: 35px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.modal1__btn_layout{
    display: flex;
    /* background-color: #1b6aae; */
    margin-top: 15px;
    gap: 25px;
}
  .modal1__align {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  
  }
  
  .modal1__content {
    width: 700px;
    height: 150px;
    box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07),
      0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
      0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
      0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
    border-radius: 20px;
    /* background: transparent; */
    color: #000;
    margin: 0rem 4rem;
    background-color: rgb(253, 252, 252);
  }
  
  
  


  .modal1__msg {
    display: flex;
    padding: 32px;
    justify-content: center;

    flex-direction: column;
   
  }
  
 
  
 
 
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media screen and (max-width: 800px) {
    .modal1__content {
      margin: 0rem 1rem;
      width: 100%;
    }
    .modal1__video-style {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 499px) {
    .modal1__content {
      background: transparent;
      height: auto;
    }
    .modal1__video-align {
      bottom: 0px;
    }
    .modal1__video-style {
      height: auto;
    }
  }

  .modal2__bg {
    /* margin-top: 25px; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(214, 214, 214, 0.19);
    /* background-color: red; */
    backdrop-filter: blur(3px);
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    z-index: 100000 !important;
  }
  
.modal2__btn{
    width: 120px;
    height: 35px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.modal2__btn_layout{
    display: flex;
    /* background-color: #1b6aae; */
    margin-top: 15px;
    gap: 25px;
}
.modal3__btn{
  width: 120px;
  height: 35px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.adminNav{
  display: flex;
  /* background-color: aqua; */
  align-items: center;
  width: 300px;
  gap: 60px;
  white-space: nowrap;
  
}

.adminlist{
  color: #B8202C !important;
}

.adminlist:active{
  color: #1d0709 !important;
}
:hover.adminlist{
  color: #f42c3c !important;
}
.adminlistNav{
  color: #380f12 !important;
}

.adminNavList{
  display: flex;
  flex-direction: row;
  /* background-color: #aa3f46; */
  color: #B8202C !important;
  gap: 20px;
}
.admintitle{
  cursor: pointer;
  /* background-color: red; */
  color: #B8202C !important;
}
.table__btn{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  /* justify-items: center; */
  width: 90px;
  height: 20px;
}
.table_btn1_color{
  font-size: 11px;
  background-color: rgb(177, 176, 176);
  color: white;
}
:hover.table_btn1_color{
  background-color: rgb(130, 130, 130);
  color: white;
}
.table_btn2_color{
  background-color: rgb(74, 125, 234);
  font-size: 11px;
  color: white;
}
:hover.table_btn2_color{
  background-color: rgb(52, 91, 175);
  color: white;
}
.table_btn3_color{
  font-size: 11px;
  background-color: rgb(77, 200, 68);
  color: white;
}
:hover.table_btn3_color{
  background-color: rgb(61, 164, 54);
  color: white;
}
.table_btn4_color{
  background-color: #df444e;
  color: whitesmoke;
  font-size: 11px;
}
.table_btn4_color:hover{
  background-color: #aa3f46;
}

.table_layout_btn{
display: flex;
justify-content: space-evenly;

/* width: 800px; */
/* width:fit-content; */
/* background-color: aliceblue; */

}

.td_border{
  border-top: none !important;
}


.modal3__btn_layout{
  display: flex;
  justify-content: space-between;
  /* background-color: #1b6aae; */
  margin-top: 15px;
  gap: 25px;
}

  .modal2__align {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  
  }
  
  .modal2__content {
    width: 700px;
    height: fit-content;
    box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07),
      0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
      0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
      0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
    border-radius: 20px;
    /* background: transparent; */
    color: #000;
    margin: 0rem 4rem;
    background-color: rgb(253, 252, 252);
  }
  
  .modal2__input{
    background-color: rgb(241, 240, 240);
    padding:8px 0px;
    padding-left:14px;
    font-size:14px;
    border: none;
    border-color:rgba(131,147,147,.2);
    border-radius:8px;
    padding:8px;
    width: 300px;
}
  


  .modal2__msg {
    display: flex;
    padding: 32px;
    justify-content: center;

    flex-direction: column;
   
  }

  .back_course{
    display: flex;
    justify-content: end;
    color:red !important;
  }
  .back_course_para{
   cursor: pointer;
    color:red !important;
  }
  :hover.back_course_para{
    cursor: pointer;
     color:red !important;
     text-decoration: underline !important;
   }
  .updateDiv{
    display: flex;
    justify-content: space-between;
  }
  .updateDiv_para{
    font-size: 13px;
  }
 .sign-in{

  display: flex;
  /* justify-content: center !important;
  align-items: center !important; */
  font-size: 15px;
  margin-left: 16px;
  margin-top: 12px;
cursor: pointer;
 }
 :hover.sign-in{
text-decoration: underline !important;
  display: flex;
  /* justify-content: center !important;
  align-items: center !important; */
  font-size: 15px;
  margin-left: 16px;
  margin-top: 12px;
cursor: pointer;
 }

 .text_nowrap{

  white-space: nowrap;
 }