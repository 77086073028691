//
// general.scss
//

body {
  font-family: $font-family-base;
  color: $black;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito';
  font-weight: 500;
  line-height: 1.3;
}

a {
  text-decoration: none;
  outline: none;
}

p {
  line-height: 1.6;
  color: $muted;
}

html {
  scroll-padding-top:70px;
  scroll-behavior: smooth;
}

.row > * {
  position: relative;
}
