//
// switcher.scss
//

#style-switcher {
    transition: all 0.4s;
    background: none repeat scroll 0 0 $white;
    box-shadow: 0px 4px 12px rgba($dark,0.07);
	left: -189px;
	position: fixed;
    border-bottom-right-radius: 5px;
	top: 26%;
	width: 189px;
	z-index: 999;
	padding: 10px 5px;
	.settings {
		background: $gray-800;
		color: $white;
		font-size: 20px;
		position: absolute;
		right: -45px;
		top: 0;
		height: 45px;
		width: 45px;
		line-height: 45px;
		text-align: center;
	}
}
ul.pattern {
	overflow: hidden;
	border-radius: 0px;
	li {
		float: left;
		margin: 2px;
		a {
			display: block;
			height: 35px;
			width: 35px;
			border-radius: 30px;
			margin: 5px;
		}
	}
	.color1 {
		background-color: $primary;
	}
	.color2 {
		background-color:$success;
	}
	.color3 {
		background-color: $warning;
	}
	.color4 {
		background-color: $blue;
	}
	.color5 {
		background-color: $info;
	}
	.color6 {
		background-color: $purple;
	}
}

@media only screen and (max-width: 479px) {
	#style-switcher {
		display: none;
	}
}