/* Template Name: Kasy
   File Description: Main Css file of the template
*/
/****************************************

01.variables
02.general
03.helper
04.components
05.avatar
06.menu
07.home
08.cta
09.client-logo
10.services
11.blog
12.features


*****************************************/
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700;800&family=Roboto&display=swap");
body {
  font-family: "Roboto", sans-serif;
  color: #252d31;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito";
  font-weight: 500;
  line-height: 1.3;
}

a {
  text-decoration: none;
  outline: none;
}

p {
  line-height: 1.6;
  color: #839393;
}

.curs_p{
  cursor: pointer;
}

html {
  scroll-padding-top: 70px;
  scroll-behavior: smooth;
}

.row > * {
  position: relative;
}

/*******color Loop*******/
.text-primary {
  color: #B8202C !important;
}

.bg-primary {
  background-color: #B8202C !important;
}

.bg-soft-primary {
  background-color: rgba(255, 128, 93, 0.18) !important;
}

.text-success {
  color: #06ba63 !important;
}

.bg-success {
  background-color: #06ba63 !important;
}

.bg-soft-success {
  background-color: rgba(6, 186, 99, 0.18) !important;
}

.text-info {
  color: #49c6e5 !important;
}

.bg-info {
  background-color: #49c6e5 !important;
}

.bg-soft-info {
  background-color: rgba(73, 198, 229, 0.18) !important;
}

.text-warning {
  color: #f4b942 !important;
}

.bg-warning {
  background-color: #f4b942 !important;
}

.bg-soft-warning {
  background-color: rgba(244, 185, 66, 0.18) !important;
}

.text-danger {
  color: #ef4b4b !important;
}

.bg-danger {
  background-color: #ef4b4b !important;
}

.bg-soft-danger {
  background-color: rgba(239, 75, 75, 0.18) !important;
}

.text-light {
  color: #bbbaba !important;
}

.bg-light {
  background-color: #bbbaba !important;
}

.bg-soft-light {
  background-color: rgba(187, 186, 186, 0.18) !important;
}

.text-dark {
  color: #152c5c !important;
}

.bg-dark {
  background-color: #152c5c !important;
}

.bg-soft-dark {
  background-color: rgba(21, 44, 92, 0.18) !important;
}

.text-muted {
  color: #839393 !important;
  font-size: 14px;
}

.bg-muted {
  background-color: #839393 !important;
}

.bg-soft-muted {
  background-color: rgba(131, 147, 147, 0.18) !important;
}

.text-white {
  color: #ffffff !important;
}

.font-alert{
margin-top: -30px;
  position: absolute;
  font-size: 13px;
  color:red !important ;

  /* height: 10px; */
 
  /* width: 290px; */
  white-space: nowrap;
}
.font-alert-g{
  margin-top: -30px;
    position: absolute;
    font-size: 13px;
    color:green !important ;
  
    /* height: 10px; */
   
    /* width: 290px; */
    white-space: nowrap;
  }

.font-alert-c{
  margin-top: -30px;
    position: absolute;
    font-size: 15px !important;
    color:red !important ;
  
    /* height: 10px; */
   
    /* width: 290px; */
    white-space: nowrap;
  }
  .font-alert-c-g{
    margin-top: -30px;
      position: absolute;
      font-size: 16px !important;
      color:green !important ;
    /* font-weight: 500 !important; */
      /* height: 10px; */
     
      /* width: 290px; */
      white-space: nowrap;
    }
  

.font-alert1{
  margin-top: -35px;
    position: absolute;
    font-size: 18px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 290px;
    white-space: nowrap;
    color: red!important;
  }
  .font-alert3{
    margin-top: -35px;
      position: absolute;
      font-size: 18px;
      height: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 290px;
      white-space: nowrap;
      color: green!important;
    }


.bg-white {
  background-color: #ffffff !important;
}

.bg-soft-white {
  background-color: rgba(255, 255, 255, 0.18) !important;
}

.signout{
  font-size: 16px;
  cursor: pointer;
  color:#B8202C ;
}
.signout:hover{
  font-size: 16px;
  cursor: pointer;
  color:#ef2132 !important ;
}

.reg{
  /* background-color: red; */
  height: 300px;
  padding:5px ;
  padding-right: 8px;
  overflow-y: auto;
}

.ardLogo{
  display: flex;
  margin-left: 85px;
  cursor: pointer;
}

.section {
  padding-top: 40px;
  padding-bottom: 80px;
  position: relative;
}
.section-welcome {
  padding-top: 40px;
  padding-bottom: 10px;
  position: relative;
}


@media (max-width: 576px) {
  .section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .section-welcome {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
/*******font-size*******/
.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

/**********bg-overlay***************/
.bg-overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(131deg, #2e3140, transparent);
}

/************overflow-x**************/
.overflow-hidden-x {
  overflow-x: hidden;
}

/***********heading***************/
.heading {
  font-size: 34px;
}
.heading span {
  position: relative;
}
.heading span::after {
  content: "";
  position: absolute;
  height: 4px;
  width: 100%;
  background-color: #252d31;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
}

.heading2 {
  font-size: 22px;
  margin-top:-10px ;
  margin-bottom:30px ;
}
.heading2 span {
  position: relative;
}
.heading2 span::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #252d31;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
}

/* .home-shape-arrow i {
  background: white;
  border: 1px solid #B8202C;
  height: 50px;
  width: 50px;
  margin: 0 auto;
  line-height: 50px;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
} */
.home-shape-arrow .arrow-icon {
  position: absolute;
  top: -26px;
  left: 0;
  right: 0;
  text-align: center;
  display: inline-block;
}

#back-to-top {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 20px;
  z-index: 999;
  right: 30px;
  background: #B8202C;
  border-color: transparent;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
  display: none;
}
#back-to-top:hover {
  background-color: white;
  color: #252d31;
  border: 1px solid;
}

/*******font-weight*******/
.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

/*******link color*******/
.primary-link {
  color: #152c5c;
  transition: all 0.5s ease;
}
.primary-link:hover {
  color: #B8202C;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
}
.btn {
  padding: 13px 36px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.5s ease;
  position: relative;
  border-radius: 25px;
}
.btn:hover {
  text-shadow: 0px 0.2px 0px black;
}
.btn:focus {
  box-shadow: none;
}

.btn-primary {
  background: #B8202C;
  border-color: #B8202C !important;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary.focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary .open > .dropdown-toggle.btn-primary {
  background: #d63944;
  border-color: #d63944;
  box-shadow: 0px 8px 20px -6px rgba(255, 128, 93, 0.6);
  transform: translateY(-6px);
}

.btn-outline-primary {
  color: #B8202C;
  border-color: #B8202C;
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.focus, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary .open > .dropdown-toggle.btn-primary {
  background: #B8202C;
  border-color: #B8202C;
  box-shadow: 0px 8px 20px -6px rgba(255, 128, 93, 0.6);
  transform: translateY(-6px);
}

.btn-success {
  background: #06ba63;
  border-color: #06ba63 !important;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success.focus, .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success .open > .dropdown-toggle.btn-primary {
  background: #05a658;
  border-color: #05a658;
  box-shadow: 0px 8px 20px -6px rgba(6, 186, 99, 0.6);
  transform: translateY(-6px);
}

.btn-outline-success {
  color: #06ba63;
  border-color: #06ba63;
}
.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.focus, .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success .open > .dropdown-toggle.btn-primary {
  background: #06ba63;
  border-color: #06ba63;
  box-shadow: 0px 8px 20px -6px rgba(6, 186, 99, 0.6);
  transform: translateY(-6px);
}

.btn-info {
  background: #49c6e5;
  border-color: #49c6e5 !important;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info.focus, .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info .open > .dropdown-toggle.btn-primary {
  background: #37c0e2;
  border-color: #37c0e2;
  box-shadow: 0px 8px 20px -6px rgba(73, 198, 229, 0.6);
  transform: translateY(-6px);
}

.btn-outline-info {
  color: #49c6e5;
  border-color: #49c6e5;
}
.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.focus, .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info .open > .dropdown-toggle.btn-primary {
  background: #49c6e5;
  border-color: #49c6e5;
  box-shadow: 0px 8px 20px -6px rgba(73, 198, 229, 0.6);
  transform: translateY(-6px);
}

.btn-warning {
  background: #f4b942;
  border-color: #f4b942 !important;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning.focus, .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning .open > .dropdown-toggle.btn-primary {
  background: #f3b22f;
  border-color: #f3b22f;
  box-shadow: 0px 8px 20px -6px rgba(244, 185, 66, 0.6);
  transform: translateY(-6px);
}

.btn-outline-warning {
  color: #f4b942;
  border-color: #f4b942;
}
.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.focus, .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning .open > .dropdown-toggle.btn-primary {
  background: #f4b942;
  border-color: #f4b942;
  box-shadow: 0px 8px 20px -6px rgba(244, 185, 66, 0.6);
  transform: translateY(-6px);
}

.btn-danger {
  background: #ef4b4b;
  border-color: #ef4b4b !important;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .btn-danger.focus, .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger .open > .dropdown-toggle.btn-primary {
  background: #ed3838;
  border-color: #ed3838;
  box-shadow: 0px 8px 20px -6px rgba(239, 75, 75, 0.6);
  transform: translateY(-6px);
}

.btn-outline-danger {
  color: #ef4b4b;
  border-color: #ef4b4b;
}
.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.focus, .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger .open > .dropdown-toggle.btn-primary {
  background: #ef4b4b;
  border-color: #ef4b4b;
  box-shadow: 0px 8px 20px -6px rgba(239, 75, 75, 0.6);
  transform: translateY(-6px);
}

.btn-light {
  background: #bbbaba;
  border-color: #bbbaba !important;
}
.btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active, .btn-light.focus, .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light .open > .dropdown-toggle.btn-primary {
  background: #b1b0b0;
  border-color: #b1b0b0;
  box-shadow: 0px 8px 20px -6px rgba(187, 186, 186, 0.6);
  transform: translateY(-6px);
}

.btn-outline-light {
  color: #bbbaba;
  border-color: #bbbaba;
}
.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.focus, .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light .open > .dropdown-toggle.btn-primary {
  background: #bbbaba;
  border-color: #bbbaba;
  box-shadow: 0px 8px 20px -6px rgba(187, 186, 186, 0.6);
  transform: translateY(-6px);
}

.btn-dark {
  background: #152c5c;
  border-color: #152c5c !important;
}
.btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active, .btn-dark.focus, .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark .open > .dropdown-toggle.btn-primary {
  background: #11244b;
  border-color: #11244b;
  box-shadow: 0px 8px 20px -6px rgba(21, 44, 92, 0.6);
  transform: translateY(-6px);
}

.btn-outline-dark {
  color: #152c5c;
  border-color: #152c5c;
}
.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.focus, .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark .open > .dropdown-toggle.btn-primary {
  background: #152c5c;
  border-color: #152c5c;
  box-shadow: 0px 8px 20px -6px rgba(21, 44, 92, 0.6);
  transform: translateY(-6px);
}

.btn-muted {
  background: #839393;
  border-color: #839393 !important;
}
.btn-muted:hover, .btn-muted:focus, .btn-muted:active, .btn-muted.active, .btn-muted.focus, .btn-muted:not(:disabled):not(.disabled):active, .btn-muted:not(:disabled):not(.disabled):active:focus,
.btn-muted .open > .dropdown-toggle.btn-primary {
  background: #788a8a;
  border-color: #788a8a;
  box-shadow: 0px 8px 20px -6px rgba(131, 147, 147, 0.6);
  transform: translateY(-6px);
}

.btn-outline-muted {
  color: #839393;
  border-color: #839393;
}
.btn-outline-muted:hover, .btn-outline-muted:focus, .btn-outline-muted:active, .btn-outline-muted.active, .btn-outline-muted.focus, .btn-outline-muted:not(:disabled):not(.disabled):active, .btn-outline-muted:not(:disabled):not(.disabled):active:focus,
.btn-outline-muted .open > .dropdown-toggle.btn-primary {
  background: #839393;
  border-color: #839393;
  box-shadow: 0px 8px 20px -6px rgba(131, 147, 147, 0.6);
  transform: translateY(-6px);
}

.btn-white {
  background: #ffffff;
  border-color: #ffffff !important;
}
.btn-white:hover, .btn-white:focus, .btn-white:active, .btn-white.active, .btn-white.focus, .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white .open > .dropdown-toggle.btn-primary {
  background: whitesmoke;
  border-color: whitesmoke;
  box-shadow: 0px 8px 20px -6px rgba(255, 255, 255, 0.6);
  transform: translateY(-6px);
}

.btn-outline-white {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-white:hover, .btn-outline-white:focus, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.focus, .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white .open > .dropdown-toggle.btn-primary {
  background: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 8px 20px -6px rgba(255, 255, 255, 0.6);
  transform: translateY(-6px);
}

.btn.btn-outline-white:focus, .btn.btn-outline-white:active, .btn.btn-outline-white:hover, .btn.btn-outline-white.active, .btn.btn-outline-light:focus, .btn.btn-outline-light:active, .btn.btn-outline-light:hover, .btn.btn-outline-light.active {
  color: #152c5c;
}
.btn.btn-outline-primary:focus, .btn.btn-outline-primary:active, .btn.btn-outline-primary:hover, .btn.btn-outline-primary.active, .btn.btn-outline-dark:focus, .btn.btn-outline-dark:active, .btn.btn-outline-dark:hover, .btn.btn-outline-dark.active, .btn.btn-outline-success:focus, .btn.btn-outline-success:active, .btn.btn-outline-success:hover, .btn.btn-outline-success.active {
  color: #ffffff !important;
}
.cls-1{fill:none;stroke:#155724;stroke-linecap:round;stroke-linejoin:round;stroke-width:20px;}

.avatar-xs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-sm {
  height: 3.2rem;
  width: 3.2rem;
}

.avatar-md {
  height: 4rem;
  width: 4rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  background-color: #B8202C;
  color: #ffffff;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.avatar-group {
  padding-left: 12px;
  display: flex;
  flex-wrap: wrap;
}
.avatar-group .avatar-group-item {
  margin-left: -12px;
  border: 2px solid #f8f9fa;
  border-radius: 50%;
  transition: all 0.2s;
}
.avatar-group .avatar-group-item:hover {
  position: relative;
  transform: translateY(-2px);
}

.navbar-custom {
  background-color: transparent;
  padding: 18px 0px;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  transition: all 0.5s ease-in-out;
}
.navbar-custom .navbar-brand {
  margin-right: 0;
}
.navbar-custom .navbar-brand .logo-light {
  display: inline-block;
}
.navbar-custom .navbar-brand .logo-dark {
  display: none;
}
.navbar-custom .navbar-collapse .navbar-nav .nav-item .nav-link {
  font-size: 14.5px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 14px;
  transition: all 0.5s;
  position: relative;
  font-weight: 500;
}
.navbar-custom .navbar-collapse .navbar-nav .nav-item .nav-link.active {
  color: #B8202C;
}
.navbar-custom .navbar-collapse .navbar-nav .nav-item .nav-link.active::before {
  width: 100%;
}
.navbar-custom .navbar-collapse .navbar-nav .nav-item .nav-link:hover {
  color: #B8202C;
}
.navbar-custom .navbar-collapse .nav-text .nav-item .nav-link {
  color: #252d31;
}
.navbar-custom .navbar-collapse .nav-btn {
  padding: 7px 25px;
  border-radius: 50px;
  font-size: 13px;
  letter-spacing: 1px;
}
.navbar-custom .navbar-toggler {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: #152c5c;
}
.navbar-custom .navbar-toggler:focus {
  box-shadow: 0 0 0 1.25px;
}

/*---NAVBAR STICKY--*/
.nav-sticky.navbar-custom {
  margin-top: 0px;
  padding: 18px 0px;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px 0px rgba(38, 107, 193, 0.08);
}
.nav-sticky .navbar-brand .logo-dark {
  display: inline-block;
}
.nav-sticky .navbar-brand .logo-light {
  display: none;
}
.nav-sticky .navbar-nav .nav-item .nav-link {
  color: #252d31 !important;
}
.nav-sticky .navbar-nav .nav-item .nav-link.active {
  color: #B8202C !important;
}
.nav-sticky .navbar-nav .nav-item .nav-link:hover {
  color: #B8202C !important;
}
.nav-sticky .navbar-nav .nav-item .nav-link::before {
  background-color: #B8202C !important;
}
.nav-sticky .nav-btn {
  background-color: #B8202C;
  color: white;
}

.modal .modal-dialog .modal-content .modal-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 2;
}
.modal .modal-dialog .modal-content .modal-body .modal-title {
  font-weight: 600;
}
.modal .modal-dialog .modal-content .modal-footer .footer-text {
  margin-bottom: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
}

@media (max-width: 768px) {
  .navbar-custom {
    padding: 18px 0px;
    background-color: white;
  }
  .navbar-custom .navbar-brand {
    margin-left: 14px;
  }
  .navbar-custom .navbar-brand .logo-dark {
    display: inline-block !important;
  }
  .navbar-custom .navbar-brand .logo-light {
    display: none !important;
  }
  .navbar-custom .nav-item .nav-link {
    display: inline-block;
    color: #252d31 !important;
  }
  .navbar-custom .nav-item .nav-link.active {
    color: #B8202C;
  }

  .navbar-nav .nav-item .nav-link {
    color: #252d31 !important;
  }
  .navbar-nav .nav-item .nav-link.active {
    color: #B8202C !important;
  }
  .navbar-nav .nav-item .nav-link:hover {
    color: #B8202C !important;
  }
  .navbar-nav .nav-item .nav-link::before {
    background-color: #B8202C !important;
  }

  .nav-btn {
    background-color: #B8202C !important;
    color: white !important;
  }
}
.home {
  background-position: center;
  background-size: cover;
}
.home .home-heading h1 {
  font-size: 45px;
  font-weight: 600;
  line-height: 1.4;
}
.home .home-btn {
  margin-top: 30px;
}
.home .home-btn .modal-btn {
  position: relative;
  padding: 13px 52px 13px 30px;
}
.home .home-btn .modal-btn span {
  position: absolute;
  align-items: center;
  justify-content: center;
}
.home .home-btn .modal-btn span i {
  font-size: 34px;
}
.home .home-btn .modal-btn:hover .btn-icon, .home .home-btn .modal-btn:focus .btn-icon {
  background-color: #ffffff;
  color: #B8202C;
  transition: all 0.9s ease;
}
.home .home-modal {
  border: 0;
  background-color: transparent;
}
.home .home-modal .btn-close {
  width: 24px;
  height: 24px;
  background-color: rgba(21, 44, 92, 0.5);
  border-radius: 4px;
  position: relative;
  top: 60px;
  right: 3px;
  z-index: 3;
}
.home .home-modal .btn-close:hover, .home .home-modal .btn-close:focus {
  opacity: 0.5;
}
.home .home-modal video:focus {
  outline: none;
}

.home-1 {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.home-1::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #152c5c;
  z-index: -3;
  width: 65%;
  left: auto;
  right: 0;
}
.home-1::after {
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  left: auto;
  right: 0;
  background: #f1f0fe;
  width: 27%;
  z-index: -1;
}
.home-1 .left-img img {
  position: absolute;
  top: 16%;
  z-index: -1;
}
.home-1 .home-heading .home-text {
  height: 30px;
  background-color: #f1f0fe;
}

@media (max-width: 1140px) {
  .home-1::after {
    width: 100%;
  }
  .home-1 .left-img img {
    display: none;
  }
}
@media (max-width: 1440px) {
  .home-1 .home-img {
    height: auto;
    max-width: 100%;
  }
}
@media (max-width: 576px) {
  .home .modal-btn {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    display: flex;
  }
}
.home-2 {
  background-image: url(../images/ardEdu2.png);
  padding: 220px 0;
}

.home-3 {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: #28292e;
}
.home-3 .hero-img img {
  transform: perspective(750px) rotateX(0deg) rotateY(345deg);
  transition: all 0.5s ease-in-out;
}
.home-3 .hero-img img:hover {
  transform: scaleY(1);
}

.home-4 {
  background-color: #152c5c;
  padding: 180px 0;
  padding-top: 250px;
  z-index: 1;
}
.home-4 .banner-form {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -90px;
}
.home-4 .banner-form form {
  background-color: #ffffff;
  box-shadow: rgba(37, 45, 49, 0.05) 0px 8px 24px;
  margin-bottom: -140px;
  padding: 44px;
  border-radius: 20px;
}
.home-4 .banner-form form label {
  margin-bottom: 8px;
  font-size: 14px;
}
.home-4 .banner-form form .form-control {
  padding: 8px 0px;
  padding-left: 14px;
  font-size: 14px;
  border-color: rgba(131, 147, 147, 0.2);
  border-radius: 10px;
  padding: 14px;
}
.home-4 .banner-form form .form-control:focus {
  box-shadow: none;
  border-color: rgba(131, 147, 147, 0.5);
}
.home-4 .banner-form form .form-btn {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .home-4 .banner-form {
    margin-bottom: 200px;
  }
}
.home-5 {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: #28292e;
}
.home-5 #myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
.home-5 .bg-overlay {
  background-color: rgba(52, 22, 14, 0.5);
}
.home-5 .home-headign {
  font-size: 60px;
  color: #ffffff;
  margin-bottom: 30px;
  line-height: 1.1;
}
.home-5 .home-headign span {
  font-family: "Roboto", sans-serif;
  font-style: italic;
}
.home-5 .home-text {
  max-width: 720px;
  margin: auto;
  margin-bottom: 30px;
}

.home-6 {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.home-6 .bg-overlay {
  background-color: #000;
}
.home-6 .home-title {
  color: #B8202C;
}
.home-6 .heading::before {
  content: "";
  width: 40px;
  height: 3px;
  background: #B8202C;
  position: absolute;
  bottom: -30px;
}

@media (max-width: 1440px) {
  .home-6 .home-img {
    height: auto;
    max-width: 100%;
  }
}
.home-7 {
  background-image: url(../images/home-bg-7.png);
  background-size: cover;
  padding-top: 180px;
}
.home-7 .home-img {
  margin-top: 60px;
}

.home-slider .swiper-sliders {
  min-height: 100vh;
  align-items: center;
  display: flex;
}
.home-slider .bg-overlay {
  background-color: #000;
  opacity: 0.8;
}
.home-slider .swiper-button-next,
.home-slider .swiper-button-prev {
  height: 44px;
  width: 44px;
  line-height: 44px;
  border-radius: 50%;
  background-color: #B8202C;
  color: #ffffff;
}
.home-slider .swiper-button-next::after,
.home-slider .swiper-button-prev::after {
  font-size: 15px;
}
@media (max-width: 767.98px) {
  .home-slider .swiper-button-next,
.home-slider .swiper-button-prev {
    display: none;
  }
}

.home-9 {
  background: linear-gradient(291deg, #403c3c 0%, black 67%);
  position: relative;
  min-height: 100vh;
  align-items: center;
  display: flex;
}
.home-9::after {
  content: "";
  position: absolute;
  background-size: 100% !important;
  content: "";
  left: -1px;
  right: -1px;
  bottom: -1px;
  top: 0;
  background-image: url(../images/img-wave-2.png);
  background-repeat: no-repeat;
  background-position: bottom;
}
.home-9 .bg-image img {
  z-index: 1;
  border-radius: 50rem;
  position: relative;
  border-top-left-radius: 10rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

@media (max-width: 1440px) {
  .home-9 .home-img {
    height: auto;
    max-width: 100%;
  }
}
.home-10 {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.home-10::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #ffe8e1;
  z-index: -3;
  width: 73%;
  left: auto;
  right: 0;
}
.home-10::after {
  content: "";
  position: absolute;
  border-left: 10px solid #ffffff;
  height: 100%;
  top: 0;
  left: auto;
  right: 0;
  background: #fff7f4;
  width: 27%;
  z-index: -1;
}
.home-10 .left-img img {
  position: absolute;
  top: 16%;
  z-index: -1;
}
.home-10 .home-heading .home-text {
  height: 30px;
  background-color: #f1f0fe;
}

@media (max-width: 1140px) {
  .home-10::after {
    width: 100%;
    border: 0;
  }
  .home-10 .left-img img {
    display: none;
  }
}
.home-11 {
  background-image: url(../images/overlay-11.png);
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.home-12 {
  overflow: hidden;
  padding-top: 78px;
}
.home-12 .modal-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-12 .modal-btn span {
  position: absolute;
  align-items: center;
  justify-content: center;
}
.home-12 .modal-btn span i {
  font-size: 30px;
}

.cta {
  position: relative;
  background: linear-gradient(rgba(37, 45, 49, 0.7), rgba(37, 45, 49, 0.7)), url("../images/home-2.jpg") fixed no-repeat;
  color: #ffffff;
}
.cta .cta-heading {
  font-size: 35px;
  font-weight: 600;
}
.cta marquee {
  position: absolute;
  top: 0;
  background-color: rgba(37, 45, 49, 0.5);
  padding: 6px;
  font-size: 14px;
}

.client-logo .client-list img {
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.client-logo .client-list img:hover {
  opacity: 1;
}

.services {
  /* background-color: rgba(131, 147, 147, 0.04); */
}
.services .card {
  border-radius: 10px;
  border: 1px solid rgba(131, 147, 147, 0.2);
  transition: all 0.5s ease-in-out;
}
.services .card .card-body .box {
  text-align: center;
  cursor: pointer;
  
}
.services .card .card-body .box .avatar-sm {
  margin: 0 auto;
}
.services .card .card-body .box .avatar-sm .avatar-title {
  align-items: center;
  border-radius: 8px;
  
}
.services .card .card-body .box .avatar-sm .avatar-title i {
  font-size: 26px;
}
.services .card:hover {
  transform: scale(1.1);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px;
}

/* .cardiv{
  background-color: red;
  display: flex;
  justify-content: center;
} */

.services .card1 {
  margin-left: 80px;
  border-radius: 10px;
  border: 1px solid rgba(131, 147, 147, 0.2);
  transition: all 0.5s ease-in-out;
  background-color: white;
  /* white-space: nowrap; */
  
  width:900px  ;
  padding:10px ;
}
.services .card1 .card-body1 .box1 {
  text-align: center;
  
}
.services .card1 .card-body1 .box1 .avatar-sm1 {
  margin: 0 auto;
}
.services .card1 .card-body1 .box1 .avatar-sm1 .avatar-title1 {
  align-items: center;
  border-radius: 8px;
  
}
.services .card1 .card-body1 .box1 .avatar-sm1 .avatar-title1 i {
  font-size: 26px;
}
/* .services .card1:hover {
  transform: scale(1.1);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px;
} */


.features {
  background-color: rgba(131, 147, 147, 0.04);
}
.features .features-box {
  border-radius: 20px;
  padding-right:7px ;
  background-color: rgba(253, 47, 47, 0.06);
}
.features .features-box .features-body .features-icon {
  font-size: 30px;
  color: rgba(211, 35, 35, 0.8);
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  margin-top: -9px;
}
.features .features-box .features-body .features-icon:hover {
  font-size: 35px;
}
.features .features-box .features-body h6 {
  font-size: 14px;
  white-space: nowrap;
}
.features .features-box .features-body .features-img {
  border-radius: 20px;
}

.pricing {
  position: relative;
}
.pricing .pricing-box {
  padding: 46px 36px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 7px 29px 0px;
  transition: all 0.5s ease-in-out;
}
/* .pricing .pricing-box .ribbon {
  background: #B8202C;
  color: #ffffff;
  font-size: 0.9em;
  padding: 45px 54px 15px;
  transform: rotate(45deg);
  position: absolute;
  top: -23px;
  right: -60px;
  border-radius: 50%;
} */
.pricing .pricing-box .pricing-list .pricing-item {
  margin: 10px 0;
  font-size: 16px;
}
.pricing .pricing-box sub {
  bottom: -0.15rem;
  font-size: 14px;
}
.pricing .pricing-box .btn-pricing {
  background-color: #252d31;
  color: #ffffff;
}
.pricing .pricing-box:hover {
  transform: scale(1.05);
}
.pricing .pricing-box, .pricing .active {
  border: 2px solid #B8202C;
}

@media (min-width: 992px) {
  .pricing-box.pricing-active {
    transform: scale(1.05);
  }

  sup {
    top: -1.5rem;
    font-size: 29px;
  }
}
@media (max-width: 768px) {
  .bg-pricing {
    height: auto;
    padding: 80px 0;
  }
}
.testimonials {
  position: relative;
}
.testimonials .client-box {
  cursor: pointer;
  padding: 40px 25px;
  border-radius: 20px;
  transition: all 0.5s ease;
  background-color: transparent;
  margin-bottom: 20px;
}
.testimonials .client-box:hover {
  border-color: transparent;
  background-color: #f5f0f0;
}
.testimonials .client-box .client-content {
  z-index: 0;
}
.testimonials .client-box .client-content .quote-img {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: -1;
  opacity: 0.3;
}

.about .text-center {
  margin-bottom: 100px;
}
.about .img-bg {
  position: relative;
}
.about .img-bg::before {
  content: "";
  position: absolute;
  top: -60px;
  right: 40%;
  left: -80px;
  bottom: -60px;
  border-radius: 20px;
  background-color: rgba(255, 128, 93, 0.2);
}
.about .about-img {
  position: relative;
  border-radius: 20px;
}
.about .about-title {
  font-size: 26px;
}
.about .about-box {
  border: none;
}
.about .about-box .card-body {
  border-radius: 10px;
  background-color: rgba(255, 128, 93, 0.06);
}
.about .about-box .card-body .avatar-title {
  border-radius: 10px;
  align-items: center;
  background-color: #ffe6df;
}
.about .about-box .card-body .avatar-title i {
  font-size: 38px;
  color: #B8202C;
}

@media (max-width: 768px) {
  .about .text-center {
    margin-bottom: 30px;
  }
  .about .img-bg {
    margin-bottom: 40px;
  }
  .about .img-bg::before {
    display: none;
  }
}
.about .tab-pane .about-img img {
  border-radius: 20px;
}
.about .nav.nav-tabs {
  border-bottom: none;
}
.about .nav.nav-tabs .nav-item {
  padding-right: 10px;
}
.about .nav.nav-tabs .nav-item .nav-link {
  color: #252d31;
  padding: 18px 0;
  border-radius: 8px;
}
.about .nav.nav-tabs .nav-item .nav-link.active,
.about .nav.nav-tabs .nav-item .nav-link :focus {
  border: 2px solid rgba(255, 128, 93, 0.8);
  color: #B8202C;
  box-shadow: rgba(37, 45, 49, 0.27) 0px 3px 8px;
  transition: all 0.9s ease 0s;
  background: transparent;
}
.about .nav.nav-tabs .nav-item .nav-link:hover {
  border: 2px solid rgba(255, 128, 93, 0.8);
  color: #B8202C;
}
.about .tab-content {
  padding-top: 1rem;
}
.about .tab-content .accordion .accordion-item {
  border-radius: 8px;
  margin: 19px 0 0 0;
  border: 1px solid #e0e0e0;
}
.about .tab-content .accordion .accordion-item .accordion-button {
  margin: 0px 8px 0px 0;
  border-radius: 8px;
  padding: 36px 24px 10px 24px;
}
.about .tab-content .accordion .accordion-item .accordion-button::after {
  background-image: none;
  transition: all 0.5s ease;
}
.about .tab-content .accordion .accordion-item .accordion-button h6 {
  margin-bottom: 0;
  position: relative;
  padding-left: 40px;
  font-size: 18px;
}
.about .tab-content .accordion .accordion-item .accordion-button h6 span {
  position: absolute;
  font-size: 44px;
  top: -27px;
  left: 0;
}
.about .tab-content .accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.about .tab-content .accordion .accordion-item .accordion-body {
  padding: 12px 58px 8px 66px;
}
.about .tab-content .accordion .accordion-button:not(.collapsed) {
  color: #B8202C;
  background-color: transparent;
  box-shadow: none;
}
.about .tab-content .accordion .accordion-button:not(.collapsed) h6 {
  font-weight: 600;
}
.about .tab-content .accordion .accordion-button:not(.collapsed) h6 span {
  opacity: 1;
  color: #B8202C;
}
.about .tab-content .accordion .accordion-button:after {
  content: "\f0374";
  font-family: "Material Design Icons";
  color: #B8202C;
  margin-top: -20px;
}
.about .tab-content .accordion .accordion-button.collapsed:after {
  content: "\f0415";
  font-family: "Material Design Icons";
  color: #252d31;
  margin-top: -20px;
}
.about .tab-content .about-heading {
  position: relative;
  margin-bottom: 14px;
  padding-left: 22px;
}
.about .tab-content .about-heading::before {
  content: "\f012f";
  position: absolute;
  width: 10px;
  font-family: "Material Design Icons";
  font-size: 14px;
  color: #B8202C;
  left: 0;
  top: 6px;
  transition: all 0.7s ease;
}
.about .tab-content .about-heading::after {
  content: "";
  position: absolute;
  width: 19%;
  height: 2px;
  background: #B8202C;
  bottom: -2px;
  left: 10px;
}

#style-switcher {
  transition: all 0.4s;
  background: none repeat scroll 0 0 #ffffff;
  box-shadow: 0px 4px 12px rgba(21, 44, 92, 0.07);
  left: -189px;
  position: fixed;
  border-bottom-right-radius: 5px;
  top: 26%;
  width: 189px;
  z-index: 999;
  padding: 10px 5px;
}
#style-switcher .settings {
  background: #343a40;
  color: #ffffff;
  font-size: 20px;
  position: absolute;
  right: -45px;
  top: 0;
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
}

ul.pattern {
  overflow: hidden;
  border-radius: 0px;
}
ul.pattern li {
  float: left;
  margin: 2px;
}
ul.pattern li a {
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 30px;
  margin: 5px;
}
ul.pattern .color1 {
  background-color: #B8202C;
}
ul.pattern .color2 {
  background-color: #06ba63;
}
ul.pattern .color3 {
  background-color: #f4b942;
}
ul.pattern .color4 {
  background-color: #3083DC;
}
ul.pattern .color5 {
  background-color: #49c6e5;
}
ul.pattern .color6 {
  background-color: #8850f8;
}

@media only screen and (max-width: 479px) {
  #style-switcher {
    display: none;
  }
}
.team {
  background-color: rgba(131, 147, 147, 0.04);
}
.team .team-member {
  overflow: hidden;
}
.team .team-member .team-img {
  position: relative;
}
.team .team-member .team-img img {
  border-radius: 14px;
}
.team .team-member .team-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  border: 10px solid rgba(255, 128, 93, 0.6);
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  transition: all 0.3s;
  border-radius: 14px;
}
.team .team-member .team-hover .desk {
  position: absolute;
  top: 0%;
  width: 100%;
  opacity: 0;
  transform: translateY(-55%);
  transition: all 0.3s 0.2s;
  padding: 0 20px;
}
.team .team-member .team-hover .s-link {
  bottom: 10%;
  position: absolute;
  width: 100%;
  opacity: 0;
  text-align: center;
  transform: translateY(45%);
  transition: all 0.3s 0.2s;
  font-size: 35px;
}
.team .team-member .team-hover .s-link a {
  margin: 0 10px;
  color: #152c5c;
  font-size: 16px;
}
.team .team-member:hover .team-hover .desk {
  top: 35%;
}
.team .team-title {
  position: static;
  padding: 20px 0;
  display: inline-block;
  letter-spacing: 2px;
  width: 100%;
}
.team .team-title h5 {
  margin-bottom: 0px;
  display: block;
  text-transform: uppercase;
}
.team .team-title span {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.team-member,
.team-member .team-img {
  position: relative;
}

.team-member:hover .team-hover,
.team-member:hover .team-hover .desk,
.team-member:hover .team-hover .s-link {
  opacity: 1;
}

.contact .input-group-text {
  padding: 12px 15px;
  border: none;
  background-color: rgba(131, 147, 147, 0.08);
  position: absolute;
  top: 0;
  z-index: 1;
  border-radius: 0;
  bottom: 0;
  border-right: 1px solid #ced4da;
}

.form-control {
  padding: 8px 0px;
  padding-left: 54px;
}
.form-control:focus {
  box-shadow: none;
  border-color: rgba(131, 147, 147, 0.5);
}

.authentication-bg {
  background-color: rgba(255, 128, 93, 0.03);
}
.authentication-bg .auth-box {
  padding: 0px 60px;
  border-radius: 20px;
}
.authentication-bg .auth-box .auth-form label {
  font-size: 15px;
}
.authentication-bg .auth-box .auth-form a {
  font-size: 14px;
  color: #030236;
}
.authentication-bg .auth-box .auth-form .form-control {
  padding: 12px;
  padding-left: 20px;
  border-radius: 30px;
}
.authentication-bg .auth-box ::placeholder {
  color: rgba(131, 147, 147, 0.7);
  font-size: 15px;
}
.authentication-bg .auth-box .form-link {
  color: #030236 !important;
}

.bg-footer {
  background-color: rgba(255, 128, 93, 0.04);
}
.bg-footer .social-icon .list-inline-item:not(:last-child) {
  margin-right: 10px;
}
.bg-footer .social-icon li {
  display: inline-flex;
  margin-top: 20px;
}
.bg-footer .social-icon li a {
  color: #252d31;
  border: 1px solid #252d31;
  display: inline-block;
  height: 32px;
  text-align: center;
  font-size: 17px;
  width: 32px;
  line-height: 30px;
  transition: all 0.4s ease;
  border-radius: 3px;
}
.bg-footer .social-icon li a:hover {
  color: #B8202C;
  border: 1px solid #B8202C;
}
.bg-footer .icon-text {
  display: inline-flex;
}
.bg-footer .icon-text .icon {
  position: relative;
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(37, 45, 49, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.bg-footer .icon-text .icon .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background-color: white;
  color: #252d31;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(37, 45, 49, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.bg-footer .icon-text .icon .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.bg-footer .icon-text .icon i {
  font-size: 18px;
}
.bg-footer .icon-text .facebook:hover {
  background-color: #3b5999;
  color: white;
}
.bg-footer .icon-text .facebook:hover .tooltip {
  background-color: #3b5999;
  color: white;
}
.bg-footer .icon-text .facebook:hover .tooltip::before {
  background-color: #3b5999;
  color: white;
}
.bg-footer .icon-text .twitter:hover {
  background-color: #46c1f6;
  color: white;
}
.bg-footer .icon-text .twitter:hover .tooltip {
  background-color: #46c1f6;
  color: white;
}
.bg-footer .icon-text .twitter:hover .tooltip::before {
  background-color: #46c1f6;
  color: white;
}
.bg-footer .icon-text .instagram:hover {
  background-color: #e1306c;
  color: white;
}
.bg-footer .icon-text .instagram:hover .tooltip {
  background-color: #e1306c;
  color: white;
}
.bg-footer .icon-text .instagram:hover .tooltip::before {
  background-color: #e1306c;
  color: white;
}
.bg-footer .icon-text .github:hover {
  background-color: #333333;
  color: white;
}
.bg-footer .icon-text .github:hover .tooltip {
  background-color: #333333;
  color: white;
}
.bg-footer .icon-text .github:hover .tooltip::before {
  background-color: #333333;
  color: white;
}
.bg-footer .icon-text .youtube:hover {
  background-color: #de463b;
  color: white;
}
.bg-footer .icon-text .youtube:hover .tooltip {
  background-color: #de463b;
  color: white;
}
.bg-footer .icon-text .youtube:hover .tooltip::before {
  background-color: #de463b;
  color: white;
}
.bg-footer .icon-text:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.bg-footer .footer-link a {
  color: #152c5c;
  line-height: 38px;
  transition: all 0.5s;
}
.bg-footer .footer-link a:hover {
  color: #B8202C;
}
.bg-footer .footer-subcribe input {
  padding: 15px 20px;
  width: 100%;
  font-size: 14px;
  border: none;
  outline: none !important;
  padding-right: 75px;
  padding-left: 15px;
  border-radius: 5px;
  font-weight: 500;
}
.bg-footer .footer-subcribe button {
  position: absolute;
  top: 0px;
  right: 0px;
  outline: none !important;
  border-radius: 0px 5px 5px 0px;
  font-size: 14px;
  padding: 14px 20px;
}
.bg-footer .footer-subcribe form {
  position: relative;
  max-width: 400px;
}

.footer-alt {
  background-color: #bfc6ca;
  /* position: relative; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* position: fixed; */
  /* bottom: 0; */
  width: 100%;
  /* border-top: 1px solid rgba(37, 45, 49, 0.1); */
}
.footer-alt1 {
  background-color: #bfc6ca;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  /* border-top: 1px solid rgba(37, 45, 49, 0.1); */
}