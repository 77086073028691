
.admin{
    display:table;
    max-width:1100px;
    padding: 20px;
    padding-bottom:28px ;
    width:100%;
    margin:0 auto;
    box-shadow:5px 3px 8px rgba(0,0,0,0.1);
    margin-top: 150px;
    background-color: rgb(251, 251, 251);


}


.nav-align-b{

    margin-left: 30px !important;
    
   
}
textarea {
    resize: none;
  }

.modal-alert-msg{
    color:red !important;
    text-align: center;
}
.modal-alert-msg1{
    color:green !important;
    text-align: center;
}

.admin-input{

    /* background:#f7f9fc; */
    /* border:white; */
    /* font-size: 12px; */
    padding:0px;
    font-size:14px;
    /* border-bottom:1px solid #dfe7f1; */
    /* border-radius:10px; */
    /* box-shadow:none; */
    outline:none;
    /* color:inherit; */
    
    /* text-indent:px; */
    height:40px;
}
.admin-input-content{
padding: 8px;
    border-radius: 10px;
    padding-left: 8px;
    border-color: none;
    border: none;
    background-color: #eff0f39d;
}
.admin-btn{

    padding: 14px;
    width: 150px;
    font-size: 13px;
}
.admin-btn-course{
cursor: pointer;
display: flex;
align-items: center;
   
    font-size: 15px;
}

.admin-btn-course:hover{
    cursor: pointer;
    text-decoration: underline;
        /* padding: 14px; */
        /* width: 170px; */
        font-size: 15px;
    }

    .admin-btn-course1{
        cursor: pointer;
        display: flex;
        align-items: center;
           justify-content: end;
            font-size: 16px;
        }
        
        .admin-btn-course1:hover{
            cursor: pointer;
            text-decoration: underline;
                /* padding: 14px; */
                /* width: 170px; */
                font-size: 16px;
            }


.admin_btn_layout{
/* justify-content: space-between; */
justify-content: end;
gap: 20px;
    display: flex;
    /* background-color: red; */
}
.admin-alert{
    display: flex;
    justify-content: center;
    font-size: 12px;
}

/* .admin-content{
    display:block;
    background-color:rgb(231, 217, 217);
    justify-content: center;
    
    
    } */