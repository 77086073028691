.register-photoS {
    /* background:#f1f7fc; */

    padding:150px 0;
    display: flex;
    align-items: center;
    align-self:center;
    justify-self: center;
    /* height: 100% !important; */
    /* height: 86.3vh !important; */
  }
  
.register-photoS-welcome {
    /* background:#f1f7fc; */

    padding:150px 0;
    /* display: flex; */
    align-items: center;
    align-self:center;
    justify-self: center;
    /* height: 100% !important; */
    /* height: 86.3vh !important; */
  }
  
/* .main-reg{
  height: 100%;
  background-color: #B8202C;
   display: flex;
   width: 100%;
   justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
} */

  .register-photoS .image-holder {
    display:table-cell;
    width:auto;
    background:url(../images/resize/rsz_1home3-8.png);
    background-size:cover;
  }
  
  .register-photoS .form-container {
    display:table;
    max-width:900px;
    width:90%;
    margin:0 auto;
    box-shadow:5px 3px 8px rgba(0,0,0,0.1);
  }
  .register-photoS-welcome .form-container {
    display:table;
    max-width:900px;
    width:90%;
    margin:0 auto;
    box-shadow:5px 3px 8px rgba(0,0,0,0.1);
  }
  
  .register-photoS form {
    display:table-cell;
    width:400px;
    background-color:#ffffff;
    padding:40px 60px;
    color:#505e6c;
  }
  .register-photoS-welcome form {
    display:table-cell;
    width:400px;
    background-color:#ffffff;
    padding:40px 60px;
    color:#505e6c;
  }
  
  @media (max-width:991px) {
    .register-photoS form {
      padding:40px;
    }
  }
  
  .register-photoS form h2 {
    font-size:24px;
    line-height:1.5;
    margin-bottom:30px;
  }
  
  .register-photoS form .form-control {
    background:#f7f9fc;
    border:none;
    font-size: 12px;
    padding:15px;
    /* border-bottom:1px solid #dfe7f1; */
    border-radius:10px;
    box-shadow:none;
    outline:none;
    color:inherit;
    /* text-indent:px; */
    height:40px;
  }
  .register-photoS-welcome form .form-control {
    background:#f7f9fc;
    border:none;
    font-size: 12px;
    padding:15px;
    /* border-bottom:1px solid #dfe7f1; */
    border-radius:10px;
    box-shadow:none;
    outline:none;
    color:inherit;
    /* text-indent:px; */
    height:40px;
  }
  
  .register-photoS form .form-check {
    font-size:12px;
    /* line-height:20px; */
  }
  .form-check-input{
    margin-top: 3px;
    background-color: yellow !important;
    color: #B8202C !important;
    width: 12px;
  }
  .form-check{
    /* background-color: #B8202C; */
    display: flex;
    margin-top: -5px;
    /* justify-content: center;
    align-items: center; */
    justify-items: center;
    /* padding: 5px */
  }
  
  .register-photoS form .btn-primary {
    background:#B8202C;
    border:none;
    border-radius:20px;
    padding:8px;
    box-shadow:none;
    margin-top:10px;
    text-shadow:none;
    font-size: 14px;
    width: 260px;
    /* height: 25px; */
    outline:none !important;
  }
  .test input:checked ~ .checkmark {
    background-color: red;
  }

 


  
  .register-photoS form .btn-primary:hover, .register-photo form .btn-primary:active {
    background:#cf2b38;
  }
  
  .register-photoS form .btn-primary:active {
    transform:translateY(1px);
  }
  
  .register-photoS form .already {
    display:block;
    text-align:center;
    font-size:12px;
    color:#6f7a85;
    opacity:0.9;
    text-decoration:none;
  }
.already1 {
    display:block;
    text-align:center;
    font-size:13px;
    color:#6f7a85;
    opacity:0.9;
    text-decoration:none;
    cursor: pointer;
    
  }
  .already1:hover{
    cursor: pointer;
    text-decoration: underline !important;
    display:block;
    text-align:center;
    font-size:13px;
    color:#B8202C;
    opacity:0.9;
    text-decoration:none;

  }

  .already2 {
    display:block;
    text-align:left;
    font-size:13px;
    color:#6f7a85;
    opacity:0.9;
    text-decoration:none;
    cursor: pointer;
    
  }
  .already2:hover{
    cursor: pointer;
    text-decoration: underline !important;
    display:block;
    text-align:left;
    font-size:13px;
    color:#B8202C !important;
    opacity:0.9;
    text-decoration:none;

  }


  .heading3 {
    font-size: 27px;
    margin-bottom:-8px ;
    /* margin-bottom:15px; */
  }
  .heading3 span {
    position: relative;
    
  }
  .heading3 span::after {
    content: "";
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #252d31;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
  }
  .headingR {
    font-size: 20px;
    /* margin-bottom:-8px ; */
    /* margin-bottom:15px; */
  }
  .eventtext {
    font-size: 10px;
    /* margin-bottom:-8px ; */
    /* margin-bottom:15px; */
  }
  .headingSm {
    font-size: 15px;
    /* margin-bottom:-8px ; */
    /* margin-bottom:15px; */
  }
  .headingR span {
    position: relative;
    
  }
  .headingR span::after {
    content: "";
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #252d31;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
  }
  