.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

 
  .PhoneInput{
    background-color: #fefefe;
    padding: 4px;
    border-radius: 3px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .PhoneInputInput{
    padding: 7.5px;
    border: none;
  }
  .PhoneInputCountrySelectArrow{
    background: "red" !important;
    color: "red" !important;
    stroke: #000 !important;

  }
  .PhoneInputCountrySelectArrow-color{
color: #000 !important;
background-color: #000 !important;
  }
  :root {  
    --PhoneInputCountrySelectArrow-color: #000000 !important;   
    --PhoneInputCountryFlag-borderColor:"#000000" !important
  }

  .h_btn:hover{
    opacity: 0.85;
}

.cross_btn:hover{
  opacity: 0.85;
}

@media screen and (max-width: 1115px) {
  .widWebinar {
 
    width: 45%;
  }
}

@media screen and (max-width: 1115px) {
  .svgpos {
  
    right: 27%;
  }
}

@media screen and (max-width: 940px) {
  .widWebinar {
 
    width: 65%;
  }
}
@media screen and (max-width: 940px) {
  .svgpos {
 
    right: 17%;
  }
}

@media screen and (max-width: 652px) {
  .widWebinar {
 
    width: 85%;
  }

  .masterArea{
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 652px) {
  .svgpos {
 
    right: 7%;
  }
}


@media screen and (min-width: 1115px) {
  .widWebinar {
    
    width: 37%;

  }
}

@media screen and (min-width: 1115px) {
  .svgpos {
  
    right: 31.5%;
  }
}



/* .widWebinar {
 
  width: 38%;
} */