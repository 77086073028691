.reg-scroll::-webkit-scrollbar {
  width: 4px;
}
 
.reg-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #e1e2e4;
  cursor: pointer;
}
 
.reg-scroll::-webkit-scrollbar-thumb {
  background-color: rgb(223, 216, 216);
  /* outline: 1px solid slategrey; */
}





 .register-photo2 {
  display: flex;
  justify-content: center;
  align-self: center;

  padding-top: 120px;
  /* background-color: #B8202C; */
    /* background:#f1f7fc; */
    /* height: 100vh; */
    /* padding:150px 0; */
    /* height: 91vh; */
    /* min-height: 100%;  */
    
  }
  
  .register-photo2 .image-holder {
    display:table-cell;
    width:auto;
    background:url(../images/home1-8.png );
    background-size:cover;
  }
  
  .register-photo2 .form-container {
    display:table;
    max-width:900px;
    width:90%;
    margin:0 auto;
    box-shadow:5px 3px 8px rgba(0,0,0,0.1);
    
  }
  
  .register-photo2 form {
    display:table-cell;
    width:400px;
    background-color:#ffffff;
    padding:40px 60px;
    color:#505e6c;
  }
  
  @media (max-width:991px) {
    .register-photo form {
      padding:40px;
    }
  }
  
  .register-photo2 form h2 {
    font-size:24px;
    line-height:1.5;
    /* margin-bottom:30px; */
  }
  
  .register-photo2 form .form-control {
    background:#f7f9fc;
    border:none;
    font-size: 12px;
    padding:15px;
    /* border-bottom:1px solid #dfe7f1; */
    border-radius:10px;
    box-shadow:none;
    outline:none;
    color:inherit;
    /* text-indent:px; */
    height:35px;
  }
  
  .register-photo2 form .form-check {
    font-size:13px;
    /* line-height:20px; */
  }
  
  .register-photo2 form .btn-primary {
    background:#B8202C;
    border:none;
    border-radius:20px;
    padding:8px;
    box-shadow:none;
    margin-top:10px;
    text-shadow:none;
    font-size: 14px;
    width: 260px;
    /* height: 25px; */
    outline:none !important;
  }
  
  .register-photo2 form .btn-primary:hover, .register-photo form .btn-primary:active {
    background:#cf2b38;
  }
  
  .register-photo2 form .btn-primary:active {
    transform:translateY(1px);
  }
  
  .register-photo2 form .already {
    display:block;
    text-align:center;
    font-size:12px;
    color:#6f7a85;
    opacity:0.9;
    text-decoration:none;
  }
.already1 {
    display:block;
    text-align:center;
    font-size:13px;
    color:#6f7a85;
    opacity:0.9;
    text-decoration:none;
    cursor: pointer;
    
  }
  .already1:hover{
    cursor: pointer;
    text-decoration: underline !important;
    display:block;
    text-align:center;
    font-size:13px;
    color:#B8202C;
    opacity:0.9;
    text-decoration:none;

  }

  .already2 {
    display:block;
    text-align:left;
    font-size:13px;
    color:#6f7a85;
    opacity:0.9;
    text-decoration:none;
    cursor: pointer;
    
  }
  .already2:hover{
    cursor: pointer;
    text-decoration: underline !important;
    display:block;
    text-align:left;
    font-size:13px;
    color:#B8202C;
    opacity:0.9;
    text-decoration:none;

  }


  .heading4 {
    font-size: 27px;
    margin-bottom:-8px ;
    /* margin-bottom:15px; */
  }
  .heading4 span {
    position: relative;
    
  }
  .heading4 span::after {
    content: "";
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #252d31;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
  }
  